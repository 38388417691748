<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
      <div class="filter-container">
          <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">Расшифровка. {{ currCategory.name_ru }}</p>
          </div>
          <div class="right-content">
                <template v-if="categoryMode">
                    <div class="filter-actions  filter-actions-flex">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    </div>
                </template>
                <template v-else>
                    <b-button variant="light" @click="resetModal">
                        <i class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                    </b-button>
                    <b-button v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                        <i class="icon icon-plus-circle"></i>Добавить
                    </b-button>
                    <b-button
                        :disabled="!dataChanged || goodsAreSaving"
                        variant="success"
                        @click="prepareForSave">
                        Сохранить
                    </b-button>
                </template>
          </div>
      </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>

        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? 'всех' : 'выбранных'} категорий`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="text-center">
                        <button
                            class="table-plus"
                            v-b-modal.goods-modal @click="setCurrCatName(data.item)"
                            :disabled="data.item.checkForDuplicates || !data.item.name_ru || data.item.nameUpdating">
                            <i class="icon icon-plus-circle"/>
                        </button>
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-input v-if="variantAttribute"
                                      style="min-width: 90%"
                                      v-model.trim="data.item.name_ru"
                                      @blur="onNameChanged(data.item, 'name_ru')"
                                      @input="(val) => onChangeName(val, data.item, 'name_ru')"
                                      @keyup.enter.exact="keyup13">
                        </b-form-input>
                        <div v-else>{{ data.value }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick(data.item)"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <!-- <b-button v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem('данной категории', {...data.item, good_type: data.item}, data.index, true)"> -->
                        <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem('данной категории', {...data.item, good_type: data.item}, data.index, true)"></i>
                        <!-- </b-button> -->
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="3">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
            <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
                <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
            </div>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-03149-template1
                    ref="formTemplate"
                    :formCode="form.code"
                    :goodsData="goodsData"
                    :initialGoodsData="initialGoodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                ></form-03149-template1>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> Добавить</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import Form03149Template1 from "./components/budget-forms-template1.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form02_159',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, Form03149Template1, ModalAllFilesManagement, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '02-159',
                name_ru: 'РАСЧЕТ РАСХОДОВ ПО ОПЛАТЕ РАБОТ И УСЛУГ, ОКАЗАННЫХ ФИЗИЧЕСКИМИ ЛИЦАМИ, ГОСУДАРСТВЕННЫМИ ПРЕДПРИЯТИЯМИ И ОРГАНИЗАЦИЯМ С ГОСУДАРСТВЕННЫМ УЧАСТИЕМ',
                name_kk: 'Жеке тұлғалар және акцияларының бақылау пакеттері мемлекетке тиесілі мемлекеттік кәсіпорындар, акционерлік қоғамдар және мемлекеттік қатысу үлестерінің мөлшерлері қатысушылардың жалпы жиналысының шешімін анықтауға мүмкіндік беретін жауапкершілігі шектеулі серіктестіктер көрсеткен жұмыстарға және көрсетілетін қызметтерге төлеу жүргізу бойынша шығыстарды есептеу'
            },
            tableFields: [
                {
                    key: 'selection',
                    label: ' '
                },
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'total',
                    label: 'Сумма расходов (тыс.тенге)'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            isReportUploading: false,
        };
    },
    mounted() {
        this.progress = 15;
    },
    methods: {

        itemUpdate(item) {
            this.$set(item, 'name_ru', '');
            this.$set(item, 'code', this.uuidv4());
            this.$set(item, 'amount', 0);
            this.$set(item, 'price', 0);
            this.$set(item, 'total', 0);
            this.$set(item, 'newCat', true);
            this.$set(item, 'checkForDuplicates', false);
            this.$set(item, 'nameUpdating', false);
            this.$set(item, 'itemToDelete', false);
        }, // заполнение полей при добавлении новой категории


        async loadCategoryData() {
            this.load = true;
            this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        values.forEach((val, i) => {
                            const item = {
                                id: i,
                                name_ru: val.name_ru,
                                code: val.category_id,
                                amount: val.sum_amount,
                                price: parseFloat(val.avg_price.toFixed(2)),
                                total: parseFloat((val.sum_total / 1000).toFixed(3)),
                                newCat: false,
                                checkForDuplicates: false,
                                oldName: val.name_ru,
                                nameUpdating: false,
                                files: this.getUnicFilesArr(val),
                                itemToDelete: false,
                            };
                            this.$set(item, 'unit', null);
                            this.budgetForm.push(item);
                        });
                        this.budgetForm.sort((a, b) => a.name_ru.toLowerCase() > b.name_ru.toLowerCase() ? 1 : -1);
                    }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryData()', error.toString());
            }
            this.load = false;
        },
        fillDataForSave(item, row) {
            this.$set(item, 'name_ru', this.currCategory.name_ru);
            this.$set(item, 'code', this.currCategory.code);
            this.$set(item, 'amount', parseInt(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        prepareData(val) {
                this.$set(val, 'good_type', val.name_ru);
        },

        input12(item, field, value) {
            if (parseInt(value) > 12) {
                this.$set(item, field, 0);
                this.makeToast('danger', 'Предупреждение', 'Требуется ввести число <= 12');
            } else {
                this.$set(item, field, value);
            }
        }, // проверка value>12

        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        noAbc: function (event) {
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        getCodeFieldName() {
            return 'code';
        } // при необходимости заменить в родителе
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        }
    }
};
</script>

<style scoped>
.error .pop-up, .more-info .pop-up {
    opacity: 1;
    visibility:visible;
}
.error input {
    padding-left: 25px;
}
.filter-actions-flex {
    display: flex;
}
.table-plus {
    border: none;
}
</style>